.myArrowClassName {
    border-color: #99751A transparent transparent;
    border-width: 5px 5px 0;
}

/*.arrow-open {*/
/*    border-color: #99751A transparent transparent;*/
/*    border-width: 5px 5px 0;*/
/*}*/

/*.arrow-closed {*/
/*    border-color: #99751A transparent transparent;*/
/*    border-width: 5px 5px 0;*/
/*}*/

.myPlaceholderClassName {
    background-color: transparent;
    border-radius: 4px;
    width: 100%
}

.myControlClassName{
    background-color: transparent;
    color: #FFF;
    border: 2px solid #99751A;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .075) inset;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    width: 100%
}